import React from "react"
import Seo from "../components/SEO"
import styled from "styled-components"
import { Link } from "gatsby"
import Button from "../components/Button"
import { StaticImage } from "gatsby-plugin-image"

const Image = styled.div`
  margin: calc(var(--spacing) * 2) 0;
  @media (min-width: 1200px) {
    margin: calc(var(--spacing) * 4) 0;
  }
`

const Placeholder = styled.div`
  height: 10vh;
`

const ServicesPage = () => (
  <>
    <Seo title="Services" description="Our services" />
    <h1>Our services</h1>
    <p>GreenFintech.org works with fintechs, financial institutions, governments and NGOs to build tomorrow’s green finance organisations and innovations.</p>
    <p>Our mission is to inspire and drive green finance and technology. To use advanced AI, blockchain, IoT and cloud technologies to do good. Creating partnerships and to deliver collaborative green and sustainable initiatives.</p>
    <p>We have detailed knowledge and an extensive international network within green, climate and sustainable fintech.</p> 
    <Image><StaticImage src='../images/priscilla-du-preez-XkKCui44iM0-unsplash.jpg' alt="GreenFintech.org" /></Image>
    <h3>What we do</h3>
    <h4>Green fintech market analysis</h4>
    <p>We have insight into the latest global green fintech initiatives, start-ups and scale-ups. We match green fintechs with investors, select the right partner for financial institutions and work with governments and NGOs to accelerate the ecosystem.</p>
    <h4>Green fintech products and services roadmap</h4>
    <p>We identify innovations to transition your existing products and services into sustainable offerings. Come up with new high value sustainable products and services which fits your organization.</p>
    <h4>Green payments solutions</h4>
    <p>Provide clients with carbon footprint metrics of each payment made with our green payments solution. Make them conscious of their carbon footprint by comparing metrics to past behavior, peer groups or own set targets.</p>
    <h4>Green savings and investment solutions</h4>
    <p>Let your clients select how their funds contribute to one or more Sustainable Development Goals (SDGs). Use proven solutions to amend your current products or launch completely new ESG products.</p>
    <h4>Green lending solutions</h4>
    <p>Build a sustainable lending portfolio by providing your clients advice and benefits for green behavior. Good for our planet, your investors and attractive for employees and new clients.</p>
    <h4>ESG data analytics</h4>
    <p>Implement and improve your ESG data capability. We implement tools and frameworks to enable you to be in control and steer towards your ESG goals.</p>
    <h4>Climate change risk analysis</h4>
    <p>Our solutions ensure early compliance to climate risk policies and frameworks. Be in the forefront of the transition to ensure you manage your transition risks and invest in low risk and high value assets.</p>
    <br/>
    <br/>
    <Placeholder></Placeholder>
    <Link href="/contact">
          <Button className="btn-link" to="/contact" text="Start our conversation" />
    </Link>
    <Placeholder></Placeholder>
  </>
)

export default ServicesPage
